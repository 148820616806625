import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import styles from "./Home.module.scss";

// import businesscard from "../../assets/faggioQRNome.png";
// import review from "../../assets/review2.png";

import Header from "../../components/Header/Header";

import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import HomeInnovationPopUp from "../../components/HomePopUp/HomeInnovationPopUp/HomeInnovationPopUp";
import HomeEnvironmentPopUp from "../../components/HomePopUp/HomeEnvironmentPopUp/HomeEnvironmentPopUp";
import HomePrivacyPopUp from "../../components/HomePopUp/HomePrivacyPopUp/HomePrivacyPopUp";
import HomeCustomizationPopUp from "../../components/HomePopUp/HomeCustomizationPopUp/HomeCustomizationPopUp";
import HomeAssistancePopUp from "../../components/HomePopUp/HomeAssistancePopUp/HomeAssistancePopUp";

import reviews from "../../assets/copertinareviews2.png";

const bonsai =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/bonsai.png";
const lucchetto =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/lucchetto_9:16.png";
const disegno =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/drawing.png";
const assistenza =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/immagineassistance 9_16.png";
const arrow =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/freccia nera.png";
const businesscard =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/Ecommerce.png";
const revback =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/DLC_REVIEWS_9:16.png";

const Home: React.FC = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();

  const [innovationPopUp, setInnovationPopUp] = useState(false);
  const [environmentPopUp, setEnvironmentPopUp] = useState(false);
  const [privacyPopUp, setPrivacyPopUp] = useState(false);
  const [customizationPopUp, setCustomizationPopUp] = useState(false);
  const [assistancePopUp, setAssistancePopUp] = useState(false);

  useEffect(() => {
    console.log(window.innerHeight);
  }, [window.innerHeight]);

  return (
    <>
      <div className={`${styles.fistDiv} `}>
        <video autoPlay loop muted playsInline className="d-md-block d-none ">
          <source
            src="https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/animaz_home.mp4"
            type="video/mp4"
          />
        </video>
        <video autoPlay loop muted playsInline className="d-md-none d-block ">
          <source
            src="https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/animaz_home_mobile.mp4"
            type="video/mp4"
          />
        </video>
        <Header dark={true} selected={""} />
        <div
          className={` pb-7 d-flex   flex-row align-items-start justify-content-start ps-xl-7 ps-5 col-12 ${styles.fistContainer} `}
        >
          <div
            className={`btn-home col-6 d-flex   ${styles.buttonDiscover}`}
            onClick={() => redirect("/Products")}
          >
            {t(`home_button_discover`)}
            {/* discover the products */}
          </div>
          {/* <div
            className={`col-12 ps-md-7 ps-5  mt-md-0   d-flex flex-column align-items-start justify-content-center  ${styles.textDiv} border`}
            style={{ zIndex: 2 }}
          > */}

          {/* <div className=" w-100 mt-5    d-none flex-column ">
              <span className="bigger-title d-md-block d-none d-flex text-center color-dark_grey_50 ">
                {parse(t(`home_title_1`))}
                Innovation
                <br /> for your <br />
              </span>
              <span className="bigger-title d-md-flex d-none col-12  d-flex flex-row align-items-center justify-content-center text-center  color-light">
                {parse(t(`home_title_2`))}
                efficiency
              </span>
              <span className="bigger-title-mob d-md-none d-flex  mt-5  color-dark_grey_50 ">
                {parse(t(`home_title_1`))}
                Innovation
                <br /> for your <br />
              </span>
              <span className="bigger-title-mob d-md-none d-flex  color-light ">
                {parse(t(`home_title_2`))}
                efficiency
              </span>
            </div> */}
          {/* </div> */}
        </div>
      </div>
      <div className="col-12 d-md-flex d-none flex-row align-items-start justify-content-center p-5 p-md-0 mt-7  flex-wrap ">
        <div
          className={`col-md-6 col-12 ps-md-5 pt-5 pe-md-4  d-flex  flex-row  h-100 `}
        >
          <div
            className={`  d-flex flex-column col-12 align-items-start justify-content-start ${styles.productsDiv}`}
            style={{ borderRadius: "35px", backgroundColor: "black" }}
            onClick={() => redirect("/Products/BusinessCard")}
          >
            <div
              className={`${styles.titleContainer} d-flex flex-row w-100    `}
            >
              <span className={` ${styles.cardTitle} color-light`}>
                DLC Business Card
              </span>
            </div>
            <div
              className={`d-flex flex-row w-100 px-5  col-12 ${styles.buttonContainer}`}
            >
              <div className={` ${styles.cardButton}`}>
                {t(`homepage_businesscard_card_button`)}
                {/* for a more efficient restaurant */}
              </div>
            </div>
            <div
              className={` ${styles.subDiv} d-flex flex-md-row flex-column     mt-md-5 col-12`}
            >
              <div
                className={` ${styles.imageContainer} col-md-6 col-12   d-flex flex-column align-items-md-start align-items-center justify-content-md-end justify-content-start`}
              >
                <img
                  src={businesscard}
                  className={`${styles.menu}`}
                  alt="homeBusinesscard"
                />
              </div>
              <div className="col-md-4 col-12    d-flex flex-column align-items-start justify-content-end pb-md-4 pb-xl-5 ">
                <span className="sub-title-productdiv color-light mt-md-5   ">
                  {parse(t(`homepage_menu_card_text`))}
                  {/* Enter the digital world, and offer your clients <br />a new
                  fascinating experience */}
                </span>
              </div>
              <div className="col-md-2 col-1 ms-md-3   d-flex flex-column align-items-center justify-content-end pb-md-5 pb-2">
                <div
                  className={`d-flex flex-row align-items-center justify-content-center ${styles.arrowDiv}`}
                >
                  <img
                    src={arrow}
                    className={`${styles.arrow}`}
                    alt="freccia"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-md-6 col-12 pe-md-5 pt-5 ps-md-4  d-flex  flex-row  h-100  `}
        >
          <div
            className={`  d-flex flex-column col-12  align-items-start justify-content-start ${styles.productsDiv}  `}
            style={{ borderRadius: "35px", backgroundColor: "black" }}
            onClick={() => redirect("/Products/DLCReviews")}
          >
            <div
              className={`${styles.titleContainer} d-flex flex-row w-100   `}
            >
              <span className={` ${styles.cardTitle} color-light  `}>
                DLC Reviews
              </span>
            </div>
            <div
              className={`d-flex flex-row w-100 px-5  col-12 ${styles.buttonContainer}`}
            >
              <div className={` ${styles.cardButton}`}>
                {t(`homepage_review_card_button`)}
                {/* increase your positive business reviews */}
              </div>
            </div>
            <div
              className={` ${styles.subDiv} d-flex flex-row w-100 mt-md-5    col-12`}
            >
              <div
                className={` ${styles.imageContainer} col-md-6 col-12  d-flex flex-column align-items-md-center align-items-center justify-content-end`}
              >
                <img
                  src={reviews}
                  className={`${styles.review} `}
                  alt="reviewsImage"
                />
              </div>
              <div className=" col-md-4 col-12     d-flex flex-column align-items-start justify-content-end pb-md-5 ">
                <span className="sub-title-productdiv  color-light ">
                  {t(`homepage_review_card_text`)}
                  {/* Improve your client/reviews ratio, with DLC technology */}
                </span>
              </div>
              <div className="col-md-2 col-1   ms-md-3  d-flex flex-column align-items-center justify-content-end pb-md-5 pb-2">
                <div
                  className={`d-flex flex-row  align-items-center justify-content-center ${styles.arrowDiv}`}
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "white",
                    height: "50px",
                    width: "50px",
                  }}
                >
                  <img
                    src={arrow}
                    className={`${styles.arrow}`}
                    alt="freccia"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-md-none d-flex flex-column align-items-center justify-content-start col-12  mt-10 p-4">
        <div
          className={`d-flex flex-column align-items-start color-white justify-content-start p-4 col-12 ${styles.productContainerMob}`}
          onClick={() => redirect("/Products/Businesscard")}
        >
          <span className="light-title-esquare "> Business Card</span>
          <div
            className={`text-xxl fw-normal mt-3 ${styles.productTondino} d-flex flex-row align-items-center justify-content-start p-4`}
          >
            <span>{t(`homepage_businesscard_card_button`)}</span>
          </div>
          <div
            className={` d-flex flex-row align-items-center justify-content-center mt-5  ${styles.imageContainer}`}
          >
            <img
              src={businesscard}
              alt="homebusinesscardImage"
              className={`${styles.menu}`}
            />
          </div>
          <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-3">
            {parse(t(`homepage_menu_card_text`))}
          </div>
          <div className="d-flex flex-row align-items-center justify-content-end col-12 mt-3">
            <div
              className={`d-flex flex-row  align-items-center justify-content-center ${styles.arrowDiv}`}
            >
              <img src={arrow} className={`${styles.arrow}`} alt="freccia" />
            </div>
          </div>
        </div>
        <div
          className={`d-flex flex-column align-items-start mt-10 color-white justify-content-start p-4 col-12 ${styles.productContainerMob}`}
          onClick={() => redirect("/Products/DLCReviews")}
        >
          <span className="light-title-esquare "> Reviews</span>
          <div
            className={`text-xxl fw-normal mt-3 ${styles.productTondino} d-flex flex-row align-items-center justify-content-start p-4`}
          >
            <span>{t(`homepage_review_card_button`)}</span>
          </div>
          <div
            className={` d-flex flex-row align-items-center justify-content-center mt-5  ${styles.imageContainer}`}
          >
            <img
              src={reviews}
              className={`${styles.reviewImg}`}
              alt="homereviewsImage"
            />
          </div>
          <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-5">
            {parse(t(`homepage_review_card_text`))}
          </div>
          <div className="d-flex flex-row align-items-center justify-content-end col-12 mt-3">
            <div
              className={`d-flex flex-row  align-items-center justify-content-center ${styles.arrowDiv}`}
            >
              <img src={arrow} className={`${styles.arrow}`} alt="freccia" />
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className={`col-12 d-flex flex-row align-items-start justify-content-center  mt-7 flex-wrap ${styles.marquee}`}
      >
        <Marquee
          direction="left"
          speed={200}
          className="w-100 h-100 d-flex flex-row"
        >
          <span
            className="bigger-title color-black"
            style={{ marginRight: "250px" }}
          >
            {t(`homepage_multilingual`)}
            
          </span>
          <span
            className="bigger-title color-dark_grey_50"
            style={{ marginRight: "250px" }}
          >
            {t(`homepage_madeinitaly`)}
            
          </span>
          <span
            className="bigger-title color-black"
            style={{ marginRight: "250px" }}
          >
            Assistance support
          </span>
          <span
            className="bigger-title color-dark_grey_50"
            style={{ marginRight: "250px" }}
          >
            {t(`homepage_ecofriendly`)}
            
          </span>
        </Marquee>
      </div> */}
      <div className={`col-12 ${styles.space}`}></div>
      <div
        className={`col-12 d-md-flex d-none flex-row align-items-center justify-content-start overflow-hidden `}
      >
        <div
          className="col-md-12  col-12 p-5 d-flex flex-row align-items-center justify-content-md-start "
          // style={{ marginInlineStart: "7rem" }}
        >
          <span
            className={`bigger-title pb-4  color-dark_grey_50 ${styles.bigTitle}`}
          >
            {t(`homepage_advantages`)}
            {/* Advantages of */}
          </span>
        </div>
      </div>
      <div
        className="col-12 d-md-flex d-none flex-row align-items-start justify-content-end overflow-hidden"
        style={{ height: "20vh" }}
      >
        <div className="col-10 mt-md-0 mt-3   d-flex flex-row align-items-center justify-content-center ">
          <span className={`bigger-title    color-black ${styles.bigTitle}`}>
            {t(`homepage_dlctech`)}
            {/* DLC? */}
          </span>
        </div>
      </div>
      {/* MOBILE ADVANTAGES OF DLC */}
      <div className="d-flex d-md-none flex-column col-12  align-items-start justify-content-starts">
        <div className="d-flex flex-row align-items-align-items-center justify-content-end bigger-title col-10 ps-5 ps-sm-0  color-dark_grey_50">
          {t(`homepage_advantages`)}
          {/* Advantages of */}
        </div>
        <div className="d-flex flex-row align-items-align-items-center justify-content-end bigger-title col-12 mt-3 pe-4 color-black">
          {t(`homepage_dlctech`)}
          {/* DLC? */}
        </div>
      </div>

      <Swiper
        className={` p-md-5 p-4  mb-10 ${styles.swiperContainer}`}
        slidesPerView={"auto"}
        spaceBetween={30}
        breakpoints={{
          300: {
            slidesPerView: 1.3,
            spaceBetween: 30,
          },
          430: {
            slidesPerView: 1.5,
            spaceBetween: 30,
          },
          600: {
            slidesPerView: 2.2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          },
        }}
      >
        <SwiperSlide
          className={`${styles.innovationSlide}`}
          onClick={() => setInnovationPopUp(!innovationPopUp)}
        >
          {/* <div
              className={`swiper-slide  ${styles.innovationSlide}`}
              onClick={() => setInnovationPopUp(!innovationPopUp)}
            > */}
          <div className={`${styles.innovationBackgroundImage}`}>
            <img src={revback} alt="homereviewsBackImage" />
          </div>
          <div
            className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
          >
            {t(`Homepage_quadratoni_titoletto_1`)}
            {/* Innovation */}
          </div>
          <div
            className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
          >
            {parse(t(`Homepage_quadratoni_titolo_1`))}
            {/* Always <br /> With You */}
          </div>
          <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
            <span className={` ${styles.button}`}>
              <FontAwesomeIcon
                icon={faPlus as IconProp}
                style={{ fontSize: "2.5rem" }}
              />
            </span>
          </div>
          {/* </div> */}
        </SwiperSlide>
        <HomeInnovationPopUp
          popup={innovationPopUp}
          close={() => setInnovationPopUp(!innovationPopUp)}
        />
        <SwiperSlide
          className={`${styles.environmentSlide}`}
          onClick={() => setEnvironmentPopUp(!environmentPopUp)}
        >
          {/* <div
            className={`swiper-slide  ${styles.environmentSlide}`}
            onClick={() => setEnvironmentPopUp(!environmentPopUp)}
          > */}
          <div className={`${styles.environmentBackgroundImage}`}>
            <img src={bonsai} alt="homeEnvironmentImage" />
          </div>
          <div
            className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
          >
            {parse(t(`Homepage_quadratoni_titoletto_2`))}
            {/* Environment */}
          </div>
          <div
            className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
          >
            {parse(t(`Homepage_quadratoni_titolo_2`))}
            {/* Innovation in <br /> Conservation */}
          </div>
          <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
            <span className={` ${styles.button}`}>
              <FontAwesomeIcon
                icon={faPlus as IconProp}
                style={{ fontSize: "2.5rem" }}
              />
            </span>
          </div>
          {/* </div> */}
        </SwiperSlide>
        <HomeEnvironmentPopUp
          popup={environmentPopUp}
          close={() => setEnvironmentPopUp(!environmentPopUp)}
        />
        <SwiperSlide
          className={` ${styles.privacySlide}`}
          onClick={() => setPrivacyPopUp(!privacyPopUp)}
        >
          <div className={`${styles.privacyBackgroundImage}`}>
            <img src={lucchetto} alt="homePrivacyImage" />
          </div>
          <div
            className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
          >
            {parse(t(`Homepage_quadratoni_titoletto_3`))}
            {/* Privacy */}
          </div>
          <div
            className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
          >
            {parse(t(`Homepage_quadratoni_titolo_3`))}
            {/* Our <br /> Priority */}
          </div>
          <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
            <span className={` ${styles.button}`}>
              <FontAwesomeIcon
                icon={faPlus as IconProp}
                style={{ fontSize: "2.5rem" }}
              />
            </span>
          </div>
        </SwiperSlide>
        <HomePrivacyPopUp
          popup={privacyPopUp}
          close={() => setPrivacyPopUp(!privacyPopUp)}
        />
        <SwiperSlide
          className={`  ${styles.customizationSlide}`}
          onClick={() => setCustomizationPopUp(!customizationPopUp)}
        >
          <div className={`${styles.customizationBackgroundImage}`}>
            <img src={disegno} alt="homeCustomizationImage" />
          </div>
          <div
            className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
          >
            {parse(t(`Homepage_quadratoni_titoletto_4`))}
            {/* Customization */}
          </div>
          <div
            className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
          >
            {parse(t(`Homepage_quadratoni_titolo_4`))}
            {/* As you <br /> Want */}
          </div>
          <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
            <span className={` ${styles.button}`}>
              <FontAwesomeIcon
                icon={faPlus as IconProp}
                style={{ fontSize: "2.5rem" }}
              />
            </span>
          </div>
        </SwiperSlide>
        <HomeCustomizationPopUp
          popup={customizationPopUp}
          close={() => setCustomizationPopUp(!customizationPopUp)}
        />
        <SwiperSlide
          className={`  ${styles.assistanceSlide}`}
          onClick={() => setAssistancePopUp(!assistancePopUp)}
        >
          <div className={`${styles.assistanceBackgroundImage}`}>
            <img src={assistenza} alt="homeAssistanceImage" />
          </div>
          <div
            className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
          >
            {parse(t(`Homepage_quadratoni_titoletto_5`))}
            {/* Assistance */}
          </div>
          <div
            className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
          >
            {parse(t(`Homepage_quadratoni_titolo_5`))}
            {/* Customer <br /> Care */}
          </div>
          <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
            <span className={` ${styles.button}`}>
              <FontAwesomeIcon
                icon={faPlus as IconProp}
                style={{ fontSize: "2.5rem" }}
              />
            </span>
          </div>
        </SwiperSlide>
        <HomeAssistancePopUp
          popup={assistancePopUp}
          close={() => setAssistancePopUp(!assistancePopUp)}
        />
      </Swiper>

      <Footer dark={false} />
    </>
  );
};
export default Home;
