import { useEffect, useRef, useState } from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import styles from "./Contacts.module.scss";
import { useTranslation } from "react-i18next";

import parse from "html-react-parser";
import { useForm } from "react-hook-form";
import axiosHelper from "../../helpers/axiosHelper";

const Contacts: React.FC = () => {
  const { t } = useTranslation();
  const [from, setFrom] = useState("");
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [object, setObject] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactForm>();

  const onSubmit = async (data: ContactForm) => {
    console.log("submit");
    setFormSuccess(false);
    setFormError(false);

    if (clientName !== "") {
      data.client_name = clientName;
    } else {
      setFormError(true);
    }

    if (email !== "") {
      data.email = email;
    } else {
      setFormError(true);
    }

    if (address !== "") {
      data.address = address;
    }

    if (phone !== "") {
      data.phone_number = phone;
    }

    if (message !== "") {
      data.message = message;
    } else {
      setFormError(true);
    }
    if (object !== "") {
      data.object = object;
    } else {
      setFormError(true);
    }
    // console.log(data);

    if (!privacy) {
      setFormError(true);
    }

    if (formError === false) {
      await axiosHelper.contactsReq(data).then((response: any) => {
        setFormSuccess(true);
        setClientName("");
        setAddress("");
        setEmail("");
        setPhone("");
        setObject("");
        setMessage("");
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header dark={false} selected="Products" />
      <div
        className="d-sm-flex d-none flex-row align-items-start justify-content-around col-12 p-5 overflow-hidden"
        // style={{ height: "88vh" }}
      >
        <div className="col-7 mt-10  d-flex flex-column align-items-start justify-content-center ps-4">
          <div className="col-6 mt-4 ">
            <span className="small-title color-black">
              {parse(t(`contact_us_title`))}
              {/* Contact us <br />
              for a quote, collaborations or special requests. */}
            </span>
          </div>
          <div className="col-3 mt-10">
            <span className="sub-title color-black">
              info@dlctech.it
              <br />
              sales@dlctech.it
            </span>
          </div>
          <div className="col-6  mt-7">
            <span className="sub-title color-black">
              {/* cell +39 3801971910 */}
              {/* - Matteo Cecchetto */}
              <br />
              {/* cell +39 3457992834 */}
              {/* - Carlo De Luca */}
            </span>
          </div>
        </div>
        <div className="col-5 mt-10  ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex flex-row ps-5 pt-4 pb-1">
              <span className="sub-title color-black ">
                {t(`contact_us_name`)} *
              </span>
            </div>
            <div className="d-flex flex-row pt-2 ">
              <div className={`${styles.inputDiv}`}>
                <input
                  type="text"
                  {...register("client_name", {
                    onChange: (e) => setClientName(e.target.value),
                  })}
                  value={clientName}
                  className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center ps-1 text-helvetica"
                  placeholder={"Insert your business name"}
                />
              </div>
            </div>
            <div
              className="d-flex flex-row ps-5  pb-1 "
              style={{ paddingTop: "1.9rem" }}
            >
              <span className="sub-title color-black">
                {t(`contact_us_address`)}
                {/* Address */}
              </span>
            </div>
            <div className="d-flex flex-row pt-2">
              <div className={`${styles.inputDiv}`}>
                <input
                  type="text"
                  {...register("address", {
                    onChange: (e) => setAddress(e.target.value),
                  })}
                  value={address}
                  className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center ps-1 text-helvetica"
                  placeholder={"Insert your address"}
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-row">
              <div
                className="d-flex col-6  flex-row ps-5 pb-1 "
                style={{ paddingTop: "1.9rem" }}
              >
                <span className="sub-title color-black">
                  {t(`contact_us_email`)}
                  {/* Email */}*
                </span>
              </div>
              <div
                className="d-flex col-6  flex-row ps-5 pb-1 "
                style={{ paddingTop: "1.9rem" }}
              >
                <span className="sub-title color-black">
                  {t(`contact_us_number`)}
                  {/* Number */}
                </span>
              </div>
            </div>
            <div className="d-flex flex-row pt-2 ">
              <div className="d-flex col-6 flex-row ">
                <div className={`${styles.inputDivHalf}`}>
                  <input
                    type="mail"
                    {...register("email", {
                      onChange: (e) => setEmail(e.target.value),
                    })}
                    value={email}
                    className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center ps-1 text-helvetica"
                    placeholder={"Insert your email"}
                  />
                </div>
              </div>
              <div className="d-flex col-6 flex-row  ">
                <div className={`${styles.inputDivHalf}`}>
                  <input
                    type="text"
                    {...register("phone_number", {
                      onChange: (e) => setPhone(e.target.value),
                    })}
                    value={phone}
                    className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center ps-1 text-helvetica"
                    placeholder={"Insert your number"}
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-row ps-5 pb-1 "
              style={{ paddingTop: "1.9rem" }}
            >
              <span className="sub-title color-black">
                {t(`contact_us_object`)}
                {/* Object  */}*
              </span>
            </div>
            <div className="d-flex flex-row pt-2">
              <div className={`${styles.inputDiv}`}>
                <input
                  type="text"
                  {...register("object", {
                    onChange: (e) => setObject(e.target.value),
                  })}
                  value={object}
                  className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center ps-1 text-helvetica"
                  placeholder={"Insert your object"}
                />
              </div>
            </div>
            <div
              className="d-flex flex-row ps-5 pb-1 "
              style={{ paddingTop: "1.9rem" }}
            >
              <span className="sub-title color-black">
                {t(`contact_us_message`)}
                {/* Message */}*
              </span>
            </div>
            <div className="d-flex flex-row pt-2 ">
              <div className={`${styles.textareaDiv}`}>
                <textarea
                  {...register("message", {
                    onChange: (e) =>
                      e.target.value.length < 150 && setMessage(e.target.value),
                  })}
                  value={message}
                  className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center pt-4 text-helvetica"
                  placeholder={"Write your message here... "}
                />
              </div>
            </div>
            <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-5 ">
              <div className="col-1  ">
                <label className={`${styles.checkboxContainer}  `}>
                  <input
                    type="checkbox"
                    // checked={privacy}
                    onClick={() => setPrivacy(!privacy)}
                  />
                  <span className={`${styles.checkmark} `}></span>
                </label>
              </div>
              <div className="col-10 pe-3 " style={{ fontSize: "1.2rem" }}>
                {parse(t(`Business_preventivo_testo_privacypolicy_before`))}
                <a href="/Privacy-Policy" className="color-black ">
                  privacy policy
                </a>
                {` `}
                {parse(t(`Business_preventivo_testo_privacypolicy_after`))}
                {/* and agree to the processing of my personal data by the user or
                his company for the stated purposes. I can revoke this consent
                at any time. */}
              </div>
            </div>
            <div className="mt-5 text-end pe-5 text col-12 ">
              {formError === true && (
                <span style={{ color: "red" }}>
                  {parse(t(`Business_preventivo_error_text`))}
                  {/* Fill all the required fields and accept the privacy policy */}
                </span>
              )}
              {formSuccess === true && (
                <span style={{ color: "black" }}>
                  {parse(t(`Business_preventivo_success_text`))}
                  {/* Your request has been sent. <br /> You will be contacted as
                  soon as possible */}
                </span>
              )}
            </div>
            <div className="d-flex flex-row align-items-center justify-content-end  mt-5 col-12 pe-5">
              <div
                className={`submit d-flex flex-row align-items-center justify-content-center  col-3  ${styles.sendButton}`}
                // onClick={() => sendEmail()}
              >
                {t(`contact_us_send_button`)}
                {/* Send */}
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* MOBILE */}
      <div className="d-flex d-sm-none flex-column align-items-start justify-content-around col-12 p-5 overflow-hidden">
        <div className="col-12  d-flex flex-column align-items-start justify-content-center  ">
          <div className="col-12   ">
            <span className="small-title color-black ">
              {parse(t(`contact_us_title`))}
              {/* Contact us <br />
              for a quote, collaborations or special requests. */}
            </span>
          </div>
          <div className="col-12  mt-10">
            <span className="sub-title color-black">
              info@dlctech.it
              <br />
              sales@dlctech.it
            </span>
          </div>
          <div className="col-12 mt-7">
            <span className="sub-title color-black">
              {/* cell +39 3801971910 */}
              {/* - Matteo Cecchetto */}
              <br />
              {/* cell +39 3457992834 */}
              {/* - Carlo De Luca */}
            </span>
          </div>
        </div>
        <div className="col-12  mt-10 ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex flex-row ps-5 pt-4 pb-1">
              <span className="sub-title color-black">
                {t(`contact_us_name`)} *{/* Name */}
              </span>
            </div>
            <div className="d-flex flex-row pt-2 ">
              <div className={`${styles.inputDiv}`}>
                <input
                  type="text"
                  onChange={(e: any) => setClientName(e.target.value)}
                  value={clientName}
                  className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center ps-1 text-16"
                  placeholder={"Insert your name"}
                />
              </div>
            </div>
            <div
              className="d-flex flex-row ps-5  pb-1 "
              style={{ paddingTop: "1.9rem" }}
            >
              <span className="sub-title color-black">
                {t(`contact_us_address`)}
                {/* Address */}
              </span>
            </div>
            <div className="d-flex flex-row pt-2">
              <div className={`${styles.inputDiv}`}>
                <input
                  type="text"
                  onChange={(e: any) => setAddress(e.target.value)}
                  value={address}
                  className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center ps-1 text-16"
                  placeholder={"Insert your address"}
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-row">
              <div
                className="d-flex col-6  flex-row ps-5 pb-1 "
                style={{ paddingTop: "1.9rem" }}
              >
                <span className="sub-title color-black">
                  {t(`contact_us_email`)}
                  {/* Email */}*
                </span>
              </div>
              <div
                className="d-flex col-6  flex-row ps-5 pb-1 "
                style={{ paddingTop: "1.9rem" }}
              >
                <span className="sub-title color-black">
                  {t(`contact_us_number`)}
                  {/* Number */}
                </span>
              </div>
            </div>
            <div className="d-flex flex-row pt-2 ">
              <div className="d-flex col-6 flex-row ">
                <div className={`${styles.inputDivHalf}`}>
                  <input
                    type="email"
                    onChange={(e: any) => setEmail(e.target.value)}
                    value={email}
                    className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center ps-1 text-16"
                    placeholder={"Insert your email"}
                  />
                </div>
              </div>
              <div className="d-flex col-6 flex-row  ">
                <div className={`${styles.inputDivHalf}`}>
                  <input
                    type="text"
                    onChange={(e: any) => setPhone(e.target.value)}
                    value={phone}
                    className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center ps-1 text-16"
                    placeholder={"Insert your number"}
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-row ps-5 pb-1 "
              style={{ paddingTop: "1.9rem" }}
            >
              <span className="sub-title color-black">
                {t(`contact_us_object`)}
                {/* Object  */}*
              </span>
            </div>
            <div className="d-flex flex-row pt-2">
              <div className={`${styles.inputDiv}`}>
                <input
                  type="text"
                  onChange={(e: any) => setObject(e.target.value)}
                  value={object}
                  className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center ps-1 text-16"
                  placeholder={"Insert your object"}
                />
              </div>
            </div>
            <div
              className="d-flex flex-row ps-5 pb-1 color-black"
              style={{ paddingTop: "1.9rem" }}
            >
              <span className="sub-title ">
                {t(`contact_us_message`)}
                {/* Message  */}*
              </span>
            </div>
            <div className="d-flex  flex-row pt-2 ">
              <div className={`${styles.textareaDiv}`}>
                <textarea
                  onChange={(e: any) => setMessage(e.target.value)}
                  value={message}
                  className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center pt-4 text-16 color-dark"
                  placeholder={"Write here your message..."}
                />
              </div>
            </div>
            <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-5 ">
              <div className="col-2  ">
                <label className={`${styles.checkboxContainer}  `}>
                  <input
                    type="checkbox"
                    // checked={privacy}
                    onClick={() => setPrivacy(!privacy)}
                  />
                  <span className={`${styles.checkmark} `}></span>
                </label>
              </div>
              <div className="col-10 pe-4 " style={{ fontSize: "1.2rem" }}>
                {parse(t(`Business_preventivo_testo_privacypolicy_before`))}
                <a href="/Privacy-Policy" className="color-black ">
                  privacy policy
                </a>
                {` `}
                {parse(t(`Business_preventivo_testo_privacypolicy_after`))}
              </div>
            </div>
            <div className="mt-5 text col-12  pe-4 d-flex flex-row align-items-center justify-content-end">
              {formError === true && (
                <span
                  className=" col-9 d-flex flex-row   text-end "
                  style={{ fontSize: "1.5rem", color: "red" }}
                >
                  {parse(t(`Business_preventivo_error_text`))}
                  {/* Fill all the required fields and agree the privacy policy! */}
                </span>
              )}
              {formSuccess === true && (
                <span
                  className=" col-9 d-flex flex-row   text-end "
                  style={{ fontSize: "1.5rem", color: "black" }}
                >
                  {parse(t(`Business_preventivo_success_text`))}
                  {/* Your request has been sent. <br /> You will be contacted as
                  soon as possible */}
                </span>
              )}
            </div>
            <div className="d-flex flex-row align-items-center justify-content-end  mt-5 col-12 pe-5">
              <button
                className={`d-flex flex-row align-items-center justify-content-center col-5   ${styles.sendButton}`}
                type="submit"
              >
                {t(`contact_us_send_button`)}
                {/* Send */}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer dark={false} />
    </>
  );
};
export default Contacts;
