import parse from "html-react-parser";

import styles from "./BusinesscardContactForm.module.scss";

import { useRef, useState } from "react";
import axiosHelper from "../../helpers/axiosHelper";
import { useTranslation } from "react-i18next";
import DropDown from "../DropDown/DropDown";
import { useForm } from "react-hook-form";

const BusinesscardContactForm: React.FC = () => {
  const { t } = useTranslation();
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [object, setObject] = useState("");
  const [region, setRegion] = useState("");
  const [message, setMessage] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [potentialUsers, setPotentialUsers] = useState("");
  const [privacy, setPrivacy] = useState(false);

  const formError = useRef(false);
  const formSuccess = useRef(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BusinessForm>();

  const onSubmit = async (data: BusinessForm) => {
    formError.current = false;
    formSuccess.current = false;

    if (clientName !== "") {
      data.client_name = clientName;
    } else {
      formError.current = true;
    }

    if (email !== "") {
      data.email = email;
    } else {
      formError.current = true;
    }

    if (region !== "") {
      data.region = region;
    } else {
      formError.current = true;
    }

    if (phone !== "") {
      data.phone_number = phone;
    } else {
      formError.current = true;
    }

    if (message !== "") {
      data.message = message;
    }

    if (object !== "") {
      data.object = object;
    } else {
      formError.current = true;
    }
    if (companyName !== "") {
      data.company_name = companyName;
    } else {
      formError.current = true;
    }

    if (companySize !== "") {
      data.company_size = companySize;
    } else {
      formError.current = true;
    }

    if (potentialUsers !== "") {
      data.potential_users = potentialUsers;
    } else {
      formError.current = true;
    }

    if (privacy === false) {
      formError.current = true;
    }

    if (formError.current === false) {
      await axiosHelper.createContactRequest(data).then((response: any) => {
        formSuccess.current = true;
        setClientName("");
        setEmail("");
        setJobTitle("");
        setCompanyName("");
        setPhone("");
        setRegion("");
        setCompanySize("");
        setPotentialUsers("");
        setObject("");
        setMessage("");
        setPrivacy(false);
      });
    }
  };
  return (
    <>
      <div
        className={`
             d-flex flex-row align-items-start justify-content-center  col-12 color-black bg-white   pb-7 `}
      >
        <div
          className=" d-flex flex-column col-10 align-items-center justify-content-center "
          style={{ borderTop: "1px solid black " }}
        >
          <span className="pt-7 light-title fw-bold d-flex flex-row col-12 align-items-center justify-content-center">
            {parse(t(`Business_preventivo_titolo`))}
            {/* Richiedi un preventivo per la tua azienda */}
          </span>

          <div className="mt-4  d-flex flex-column col-12 col-md-10 align-items-center justify-content-start">
            <div className="d-flex flex-row align-items-center justify-content-center text-sm-center text-xxl ">
              {parse(t(`Business_preventivo_sottotitolo`))}
              {/* Compila il form sottostante e verrai contattato prima
                          possibile */}
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="col-12 d-flex flex-row align-items-center justify-content-center "
            >
              <div className="col-12 col-sm-10  pt-7">
                <div className="d-flex flex-sm-row flex-column align-items-center justify-content-between col-12 ">
                  <div className="d-flex flex-column col-sm-5 col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo1`))}
                    </div>
                    <div className={`${styles.inputDiv} mt-2 col-12`}>
                      <input
                        type="text"
                        {...register("client_name", {
                          onChange: (e) => setClientName(e.target.value),
                        })}
                        required
                        value={clientName || ""}
                        className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                        placeholder={`${parse(
                          t(`Business_preventivo_form_sottotitolo1`)
                        )}`}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column col-sm-6 mt-4 mt-sm-0 col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo2`))}
                    </div>
                    <div className={`${styles.inputDiv}  mt-2 col-12`}>
                      <input
                        type="email"
                        {...register("email", {
                          onChange: (e) => setEmail(e.target.value),
                        })}
                        required
                        value={email || ""}
                        className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                        placeholder={`${parse(
                          t(`Business_preventivo_form_sottotitolo2`)
                        )}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-sm-row flex-column align-items-center justify-content-between col-12 mt-4">
                  <div className="d-flex flex-column col-sm-5 col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo3`))}
                    </div>
                    <div className={`${styles.inputDiv} mt-2 col-12`}>
                      <input
                        type="text"
                        {...register("job_title", {
                          onChange: (e) => setJobTitle(e.target.value),
                        })}
                        value={jobTitle}
                        className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                        placeholder={`${parse(
                          t(`Business_preventivo_form_sottotitolo3`)
                        )}`}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column mt-4 mt-sm-0 col-sm-6 col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo4`))}
                    </div>
                    <div className={`${styles.inputDiv}  mt-2 col-12`}>
                      <input
                        type="text"
                        {...register("company_name", {
                          onChange: (e) => setCompanyName(e.target.value),
                        })}
                        value={companyName}
                        className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                        placeholder={`${parse(
                          t(`Business_preventivo_form_sottotitolo4`)
                        )}`}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-sm-row flex-column align-items-start justify-content-between col-12  mt-4">
                  <div className="d-flex flex-column col-sm-5 col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo5`))}
                    </div>
                    <div
                      className={`${styles.inputDiv} mt-2 col-12`}
                      style={{ marginBottom: "5px" }}
                    >
                      <input
                        type="text"
                        {...register("phone_number", {
                          onChange: (e) => setPhone(e.target.value),
                        })}
                        value={phone}
                        className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                        placeholder={`${parse(
                          t(`Business_preventivo_form_sottotitolo5`)
                        )}`}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column col-sm-6 col-12 mt-4 mt-sm-0 align-items-start justify-content-start ">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo6`))}
                    </div>
                    <div className="mt-2 col-12">
                      <DropDown
                        click={(e) => setRegion(e)}
                        buttonText={`${parse(
                          t(`Business_preventivo_form_sottotitolo6`)
                        )}`}
                        options={[
                          { label: "Italy", value: "IT" },
                          { label: "Europe", value: "EU" },
                          {
                            label: "United States / Canada",
                            value: "USA",
                          },
                          {
                            label: "Australia / New Zeland",
                            value: "ANZ",
                          },
                          { label: "Asia", value: "ASIA" },
                          { label: "South America", value: "SA" },
                          { label: "Other", value: "O" },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-sm-row flex-column align-items-start justify-content-between col-12  mt-4">
                  <div className="d-flex flex-column col-sm-5 col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo7`))}
                    </div>
                    <div className="mt-2 col-12">
                      <DropDown
                        click={(e) => {
                          setCompanySize(e);
                        }}
                        buttonText={`${parse(
                          t(`Business_preventivo_form_sottotitolo7`)
                        )}`}
                        options={[
                          { label: "1 - 19", value: "1" },
                          { label: "20 - 49", value: "2" },
                          { label: "50 - 99", value: "3" },
                          { label: "100 - 249", value: "4" },
                          { label: "250 - 499", value: "5" },
                          { label: "500 - 999", value: "6" },
                          { label: "1000+", value: "7" },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column col-sm-6 col-12 mt-4 mt-sm-0 align-items-start justify-content-start ">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo8`))}
                    </div>
                    <div className="mt-2 col-12">
                      <DropDown
                        click={(e) => {
                          setPotentialUsers(e);
                        }}
                        buttonText={`${parse(
                          t(`Business_preventivo_form_sottotitolo8`)
                        )}`}
                        options={[
                          { label: "1 - 19", value: "1" },
                          { label: "20 - 49", value: "2" },
                          { label: "50 - 99", value: "3" },
                          { label: "100 - 249", value: "4" },
                          { label: "250 - 499", value: "5" },
                          { label: "500 - 999", value: "6" },
                          { label: "1000+", value: "7" },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start col-12 sub-title mt-4 ps-5"
                  style={{ zIndex: 1 }}
                >
                  {parse(t(`Business_preventivo_form_titolo9`))}
                </div>
                <div className="mt-2">
                  <DropDown
                    click={(e) => setObject(e)}
                    options={[
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_1`)
                        )}`,
                        value: "demo",
                      },
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_2`)
                        )}`,
                        value: "new order",
                      },
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_3`)
                        )}`,
                        value: "existing order",
                      },
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_4`)
                        )}`,
                        value: "partnership",
                      },
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_5`)
                        )}`,
                        value: "help",
                      },
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_6`)
                        )}`,
                        value: "other",
                      },
                    ]}
                    buttonText={`${parse(
                      t(`Business_preventivo_form_sottotitolo9`)
                    )}`}
                  />
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start col-12 sub-title mt-4 ps-5"
                  style={{ zIndex: 1 }}
                >
                  {parse(t(`Business_preventivo_form_titolo10`))}
                  {/* Your message */}
                </div>
                <div className={`${styles.textareaDiv} mt-2`}>
                  <textarea
                    {...register("message", {
                      onChange: (e) =>
                        e.target.value.length < 150 &&
                        setMessage(e.target.value),
                    })}
                    value={message}
                    className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center pt-4 text-helvetica"
                    placeholder={`${parse(
                      t(`Business_preventivo_form_sottotitolo10`)
                    )}`}
                  />
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between col-12 mt-5 ">
                  <div className="col-sm-1 col-2  ">
                    <label className={`${styles.checkboxContainer}  `}>
                      <input
                        type="checkbox"
                        // checked={privacy}
                        onClick={() => setPrivacy(!privacy)}
                      />
                      <span className={`${styles.checkmark} `}></span>
                    </label>
                  </div>
                  <div className="col-9 pe-3 " style={{ fontSize: "1.2rem" }}>
                    {parse(t(`Business_preventivo_testo_privacypolicy_before`))}
                    {/* I confirm that i have read the{` `} */}
                    <a
                      href="/Privacy-Policy"
                      style={{ color: "black" }}
                      // className="color-black"
                    >
                      privacy policy
                    </a>
                    {parse(t(`Business_preventivo_testo_privacypolicy_after`))}
                    {/* {` `}
                                and agree to the processing of my personal data
                                by the user or his company for the stated
                                purposes. I can revoke this consent at any time. */}
                  </div>
                  <div className=" d-none d-sm-block col-2 d-flex flex-row align-self-end justify-content-end     ">
                    <button
                      type="submit"
                      className={`submit cursor-pointer d-flex flex-row align-items-center justify-content-center   ${styles.sendButton}`}
                      // onClick={() => sendEmail()}
                    >
                      {t(`contact_us_send_button`)}
                      {/* Send */}
                    </button>
                  </div>
                </div>
                <div className="d-flex d-sm-none flex-row align-items-center justify-content-center col-12 mt-3">
                  <button
                    type="submit"
                    className={`submit cursor-pointer d-flex flex-row align-items-center justify-content-center   ${styles.sendButton}`}
                    // onClick={() => sendEmail()}
                  >
                    {t(`contact_us_send_button`)}
                    {/* Send */}
                  </button>
                </div>
                {formSuccess.current === true && (
                  <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-5">
                    {parse(t(`Business_preventivo_success_text`))}
                    {/* Riempi i campi obbligatori per poter inviare la
                                tua richiesta! */}
                  </div>
                )}
                {formError.current === true && (
                  <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-5">
                    {parse(t(`Business_preventivo_error_text`))}
                    {/* La tua richiesta è stata inviata. */}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default BusinesscardContactForm;
