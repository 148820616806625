import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import styles from "./RemoveNewsletterAgreement.module.scss";

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axiosHelper from "../../helpers/axiosHelper";

const RemoveNewsletterAgreement: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams();

  const [email, setEmail] = useState("");
  const [successmsg, setSuccessmsg] = useState("");

  useEffect(() => {
    if (params.email) setEmail(params.email);
  }, []);

  const unsubscribe = async () => {
    await axiosHelper.revokeNewsletter(email).then((response) => {
      setSuccessmsg(response.msg);
    });
  };

  return (
    <>
      <div className="overflow-hidden bg-black">
        <Header dark={true} selected="" />
        <div
          className="d-flex   flex-column align-items-center justify-content-start col-12 color-white p-5"
          style={{ height: "88vh" }}
        >
          <div className="d-flex flex-row align-items-center justify-content-center light-title fw-bold mt-5 text-center">
            {t(`Revoke_newsletter_title`)}
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center col-12  mt-5">
            <input
              type="email"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="col-9 col-md-5 col-xl-4 text-center"
            />
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center col-12  mt-5">
            <span
              className={`${styles.submitBtn} sub-title cursor-pointer`}
              onClick={() => unsubscribe()}
            >
              {t(`Unsubscribe`)}
            </span>
          </div>

          {successmsg && (
            <div className="d-flex flex-row align-items-center justify-content-center col-12  mt-10 text-center ">
              {successmsg}
            </div>
          )}
        </div>
        <Footer dark={true} />
      </div>
    </>
  );
};
export default RemoveNewsletterAgreement;
