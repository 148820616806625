import axios from "axios";
import { error } from "console";

class axiosHelper {
  assistanceReq(data: AssistanceForm) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/assistance`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  contactsReq(data: ContactForm) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/contact`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  createContactRequest(data: BusinessForm) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/contact/business`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  createPreContact(data: PrecontactData) {
    return axios
      .post(`${process.env.REACT_APP_BUSINESS_ENDPOINT}/precontact`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  addPrecontactImage(data: uploadImageData) {
    return axios
      .put<PrecontactData>(
        `${process.env.REACT_APP_BUSINESS_ENDPOINT}/precontact/addImage/${data.uuid}`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  addPrecontactLogo(data: uploadImageData) {
    return axios
      .put<PrecontactData>(
        `${process.env.REACT_APP_BUSINESS_ENDPOINT}/precontact/addLogo/${data.uuid}`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  createCardDesign(data: CardDesignData) {
    return axios
      .post(`${process.env.REACT_APP_BUSINESS_ENDPOINT}/cardDesign`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  revokeNewsletter(email: string) {
    return axios
      .put(
        `${process.env.REACT_APP_BUSINESS_ENDPOINT}/newsletter/removeAgreement/${email}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}
export default new axiosHelper();
