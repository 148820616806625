import { useEffect, useState } from "react";

import styles from "./CardDetailCreation.module.scss";
import Dropzone from "react-dropzone";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axiosHelper from "../../helpers/axiosHelper";
import WebsiteStore, { setCurrentPrecontact } from "../../store/WebsiteStore";
import { useStoreState } from "pullstate";
import { getCurrentPrecontact } from "../../store/Selector";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import parse from "html-react-parser";
const modeNight =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/businessweb1.png";
const modeLight =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/businessweb2.png";

const CardDetailCreation: React.FC = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();
  const [image, setImage] = useState<File>();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [orderId, setOrderId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CardDesignData>();

  const onSubmit = async (data: CardDesignData) => {
    data.name = name;
    data.surname = surname;
    data.orderId = orderId;
    data.email = email;
    // console.log(data);
    if (image) {
      data.image = image;
      //   console.log(data);
    }
    await axiosHelper
      .createCardDesign(data)
      .then((response) => {
        setEmail("");
        setImage(undefined);
        setName("");
        setSurname("");
        setOrderId("");
        setSuccessMessage("Your design info has been sent!");
      })
      .catch((error) => console.log(error));
  };

  const onDropImage = (e: any) => {
    console.log(e?.[0]);
    setImage(e?.[0]);
  };

  return (
    <>
      <Header dark={false} selected={""} />
      <div className="col-12 d-flex flex-row align-items-start justify-content-center p-5 p-md-0 pt-5 flex-wrap "></div>
      <div className="p-5 d-flex flex-column align-items-center justify-content-start ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" col-12 rounded color-black "
        >
          <div>
            <div className=" d-flex flex-row  col-12 align-items-center justify-content-center  text-center">
              <span className="esquare-title">
                Fill the form to create your <br className="d-md-none" />
                card design
              </span>
            </div>
            <div className=" text-center d-flex flex-row align-items-center justify-content-center mt-10  col-12  ">
              <span className="sub-title col-md-4 col-12">
                Once you fill the form below, you will receive a mail from us
                with your card design to review them.
              </span>
            </div>
            <div className=" text-center d-flex flex-row align-items-center justify-content-center mt-10  col-12  ">
              <span className="sub-title col-md-4 col-12">
                If you have more than one card in your order, please complete
                the form multiple times.
              </span>
            </div>
            <div className=" d-flex flex-column  pb-5 align-items-center justify-content-center mt-10 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
                Name * :
              </div>
              <div className="col-12 col-md-4 mt-3 ">
                <input
                  type="text"
                  placeholder="name"
                  value={name}
                  required
                  className={`text-center w-100 sub-title ${styles.inputText}`}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
                Surname * :
              </div>
              <div className="col-12 col-md-4 mt-3">
                <input
                  type="text"
                  placeholder="surname"
                  value={surname}
                  required
                  className={`text-center w-100 sub-title ${styles.inputText}`}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
                Order Id * :
              </div>
              <div className="col-12 col-md-4 mt-3">
                <input
                  type="text"
                  placeholder="order id"
                  required
                  value={orderId}
                  className={`text-center w-100 sub-title ${styles.inputText}`}
                  onChange={(e) => setOrderId(e.target.value)}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
                Email * :
              </div>
              <div className="col-12 col-md-4 mt-3 ">
                <input
                  type="email"
                  placeholder="email"
                  required
                  value={email}
                  className={`text-center w-100 sub-title ${styles.inputText}`}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  mt-5 ">
                Logo :
              </div>
            </div>

            {!image && (
              <>
                <div className="d-flex flex-column align-items-center justify-content-center col-12  ">
                  <div
                    className={`  bg-light col-md-5 col-12 d-flex flex-row align-items-center justify-content-center ${styles.dropImage}`}
                  >
                    <Dropzone
                      onDrop={onDropImage}
                      accept={"image/png, image/jpg, image/jpeg, image/svg"}
                      maxSize={31457280}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "black",
                            borderRadius: "10px",
                          }}
                          className={` sub-title  d-flex align-items-center  justify-content-center `}
                        >
                          <div
                            className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <span>
                              Drag and drop an image or{" "}
                              <span className="color-blue">choose a file</span>{" "}
                              from your computer <br />
                              Allowed formats: .jpg, .png
                            </span>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </>
            )}
            <div
              className={`col-12 mb-7 ${
                image
                  ? "d-flex flex-row align-items-center justify-content-center"
                  : "d-none"
              }`}
            >
              <div
                className={`col-12 col-md-6   d-flex flex-column  align-items-center justify-content-center `}
              >
                {image && (
                  <>
                    <div className=" mt-4 d-flex col-12 flex-column align-items-center justify-content-center">
                      <div className=" d-flex align-items-center justify-content-end col-8">
                        <div
                          className={` ${styles.removeButton}`}
                          onClick={() => {
                            setImage(undefined);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faXmark as IconProp}
                            className=" text-16 me-0 color-red"
                          />
                        </div>
                      </div>
                      <div className={`${styles.previewImg} col-6 `}>
                        <img
                          alt="contactImage"
                          src={URL.createObjectURL(image)}
                          //   className={`${styles.previewImg}`}
                        />
                      </div>
                      <div className="d-flex mt-4 flex-row  align-items-center justify-content-center ">
                        <span className="text-xl">{image.name}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {successMessage && (
            <div className="d-flex flex-row col-12 mt-5  text text-center align-items-center justify-content-center">
              {successMessage}
            </div>
          )}
          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7   ">
            <button className="btn-black sub-title  col-6 rounded">
              CREATE DESIGN
            </button>
          </div>
        </form>
      </div>
      <Footer dark={false} />
    </>
  );
};
export default CardDetailCreation;
